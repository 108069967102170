import 'animate.css';
import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import './App.css';
import Loading from './components/loading/loading';
import NavigationBar from './components/navigationBar/navigationBar';

// code splitting
const Home = lazy(() => import('./components/home/home'));

function App() {
  return (
    <div className='el-background'>
      <BrowserRouter>
        <Suspense fallback={<Loading />}></Suspense>
        <Routes>
          <Route path='/' element={<Home />} />
        </Routes>
      </BrowserRouter>
      <NavigationBar />
    </div>
  );
}

export default App;